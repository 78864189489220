/********* FOOTER *********/
.footer{
    background-color: #282c34;
    width: 100%;
    height:120px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    position:absolute;
    bottom:0
  }
  .footerImgDiv{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    margin-top: 20px;
  }
  .footerLogo{
    width: 90px;
    height: 90px;
  }
  .footerText{
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 2vmin;
    font-family: 'Righteous', cursive;
    line-height: 2px;
    white-space: nowrap;
    text-align:center;
  }
  .github{
      text-decoration: none;
      color: rgb(57, 123, 57);
  }
  .github:hover{
      text-decoration: none;
      color:rgb(10, 142, 10);
  }
  .footPrivacy{
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;
    margin-right: 20px;
  }
  .footPrivacyLink{
    text-decoration: none;
    color: rgb(57, 123, 57);
    font-size: 2vmin;
    font-family: 'Righteous', cursive;
    line-height: 2px;
  }
  .footPrivacyLink:hover{
    text-decoration: none;
    color:rgb(10, 142, 10);
  }
  .footTerms{
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;
    margin-right: 20px;
  }
  .footTermsLink{
    text-decoration: none;
    color: rgb(57, 123, 57);
    font-size: 2vmin;
    font-family: 'Righteous', cursive;
    line-height: 2px;
  }
  .footTermsLink:hover{
    text-decoration: none;  
    color:rgb(10, 142, 10);
  }
  
  @media screen and (max-width: 600px){
    .footerText{ font-size: 10px; line-height: 4px; white-space: nowrap; }
    .footerLogo{ width: 65px; height: 65px; }
  }
  