.gameDiv{
    display:grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 1fr);
    width:100%;
    height:100%;
}
.gameWall{
    grid-row: 1/3;
    grid-column: 2/6;
}
.gameDice{
    grid-row:3/7;
    grid-column:2/6;
}
.btn{
    min-width: 100px;
    height: 40px;
    font-family: 'Righteous', cursive;
    font-size: 15px;
    border-radius:25px;
    color: white;
    background-color:rgb(10, 142, 10);
}
.btn:hover{
    color:rgb(10, 142, 10);
    background-color: rgb(0, 0, 0);
    text-shadow: -3px 2px 5px rgb(158, 181, 146);
}
.endGame, #modalContent.endGame{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.endGameTitle{
    color: rgb(18, 97, 18);
    font-size: 50px;
    text-shadow: 5px 2px 5px black;
    font-family: 'Luckiest Guy', cursive;
}
p.endGameText{
    font-weight:800;
}
a.endGameLink{
    text-decoration: none;
    color:rgb(10, 142, 10);
}
a.endGameLink:hover{
    color: rgb(18, 97, 18);
}
.endGameBtns{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

/*******START MODAL*******/
.startModalModal{   
    background-color: #282c34;
}
.startModal{
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}
.welcome{
    color: rgb(18, 97, 18);
    font-size: 25px;
    animation: 5s modalFontSize forwards ease-in;
    display: block;
    font-family: 'Righteous', cursive;
  }
.startModalRow{
    display:flex;
}
.startLogo{
    width: 15vw;
    height: 15vw;
    margin-left: 25px;
    margin-right: 25px;
}
.startModalTitle{
    margin-top: -4vh;
    animation: 5s modalFontSizeBig forwards ease-in;
    color: rgb(57, 123, 57);
    text-shadow: 5px 2px 5px black;
    width: 100%;
    font-family: 'Luckiest Guy', cursive;
}
.startModalBtns{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
@keyframes modalFontSize{
    from {
      font-size: 25px
    }
    to {
      font-size: 8px
    }
  }
  @keyframes modalFontSizeBig{
    from {
      font-size: 8px;
      opacity: 0;
    }
    to {
      font-size: 40px;
      opacity: 1;
    }
  }