.diceDiv{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(5, 1fr);
}
.diceArea{
    width: 100%;
    height: 100%;
    grid-row: 2/4;
    grid-column: 2/5;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.diceBtns{
    width: 100%;
    min-width:350px;
    height: 150%;
    margin-top: 20px;
    grid-row: 4/5;
    grid-column: 1/6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #282c34;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    animation: 3s slideUp forwards linear;
}

.dice{
    width: 12vmax;
    height:12vmax;
}
.rulesModal{
    background-color:#a4a6a8;
    width: 80vw;
    height: 60vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.rules{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Righteous', cursive;
}
.xBtn{
    display: flex;
    align-self: flex-end;
    color:rgb(10, 142, 10);
    font-size: 28px;
    font-family: 'Luckiest Guy', cursive;
    background-color: #282c34;
    border-radius: 50%;
    padding-top:10px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: -4px;
    text-align: center;
    text-justify: center;
    border-color:#c4c6c8;
    border-style: solid;
    border-width:2px;
}
.xBtn:hover{
    border-color:rgb(10, 142, 10);
    color:#c4c6c8;
}
.rulesTitle{
    color: rgb(18, 97, 18);
    font-size: 30px;
    text-shadow: 5px 2px 5px black;
    font-family: 'Luckiest Guy', cursive;
}
.rulesList{
    list-style-position: inside;
    list-style-image: url('./images/miniDice.png');
    font-size: 17px;
    line-height: 25px;
}
.rulesList p{
    margin-left:-35px;
    color:rgb(10, 142, 10);
    font-size: 28px;
    font-family: 'Luckiest Guy', cursive;
    text-shadow: 5px 2px 5px black;
}

@keyframes slideUp{
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }