.wallDiv{
    display:flex;
    flex-direction: column;
    justify-content: center ;
    margin-top: 8%;
    width:100%;
    height:100%;
}
.wall{
    background-image: url('./images/wall.png');
    background-size:cover;
    background-repeat: no-repeat;
    background-position:50% 70%;
    position: relative;
    width:100%;
    height:100%;
}
.wallBottles{
    position:absolute;
    width:60%;
    display:flex;
    justify-content: center;
    margin-left: 20%;
    margin-right:20%;
}
.wallImg{
    width:100%;
    height: 100%;
}
.bottle{
    width: 15px;
    height: 60px;
    transform: translateY(-50%)
}
.smash{
    width: 60px;
    height: 15px;
    transform: translateY(30%)
}